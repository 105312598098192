<template>

  <section id="dashboard" class="mt-4">

    <div class="container">
        <div class="row mt-5">

            <SideNav/>

            <div class="col-12 mt-sm-3 mt-md-0">

                      <table class="table mt-5">
                        <thead>
                          <tr>
                            <th scope="col">#Ticket</th>
                            <th scope="col">First</th>
                            <th scope="col">Last</th>
                            <th scope="col">Username</th>
                            <th scope="col">Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="ticket in tickets" v-bind:key="ticket.number">
                            <th scope="row">{{ticket.number}}</th>
                            <td>{{ticket.user.firstName}}</td>
                            <td>{{ticket.user.lastName}}</td>
                            <td>{{ticket.user.username}}</td>
                            <td>{{ticket.answer}}</td>
                          </tr>
                        </tbody>
                      </table>

            </div>
        </div>
    </div>
  </section>

  <Footer/>
</template>

<script>

import Api from '../../scripts/api'

import Footer from '../../components/global/Footer'
import SideNav from '../../components/admin/SideNavigator'

export default {
    components: {
        Footer,
        SideNav
    },
    props: {
      account: Object
    },
    data() {
      return {
        tickets: undefined
      }
    },
    async created() {
      var result = await Api.admin.getTickets(this.$route.params.id);
      if(result.status == 200) {
        this.tickets = await result.json();
      } else {
        this.$router.push('/');  
      }
    },
}
</script>